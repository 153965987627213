import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { REST_API } from '../../../../core/constants/api.constants';
import { ApiService } from '../../../../core/services/api.service';
import { CloudinaryService } from '../../../../core/services/cloudinary.service';
import { DialogService } from '../../../../core/services/dialog.service';
import { Author, BlogDetails, BlogTag } from '../../../../core/types/blogs';
import { FileInfo, RestApiResponse } from '../../../../core/types/core.types';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { DialogHeaderComponent } from '../../../../shared/components/dialog-header/dialog-header.component';
import { FileUploadComponent } from '../../../../shared/components/file-upload/file-upload.component';
import { SafePipe } from '../../../../shared/components/pipes/safe-url.pipe';
import { RichTextEditorComponent } from '../../../../shared/components/rich-text-editor/rich-text-editor.component';

@Component({
  selector: 'app-admin-blog-manage',
  standalone: true,
  imports: [
    FileUploadComponent,
    DialogHeaderComponent,
    ButtonComponent,
    CommonModule,
    FormsModule,
    SafePipe,
    MatSelectModule,
    RichTextEditorComponent,
  ],
  templateUrl: './admin-blog-manage.component.html',
  styleUrl: './admin-blog-manage.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminBlogManageComponent implements OnInit {
  isReqInProgress = signal(false);
  isDeleteInProgress = signal(false);
  isEdit = false;
  title = '';
  tags: number[] = [];
  authorId = -1;
  htmlString = '';
  description = '';
  blogDetails?: BlogDetails;
  public coverImage: FileInfo | undefined;

  constructor(
    private _apiService: ApiService,
    private _dialogService: DialogService,
    private _cd: ChangeDetectorRef,
    public cloudinary: CloudinaryService,
    private _dialogRef: MatDialogRef<AdminBlogManageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { authors: Author[]; tags: BlogTag[]; blogId?: number },
  ) {}

  ngOnInit(): void {
    if (this.data.blogId) {
      this.isEdit = true;
      this._fetchBlogDetails();
    }
  }

  _fetchBlogDetails() {
    this._apiService.post(REST_API.blogDetails, { blogId: this.data.blogId }).then((res: RestApiResponse) => {
      if (res.isSuccess) {
        this.blogDetails = BlogDetails.fromJson(res.data);
        this.title = this.blogDetails.title;
        this.htmlString = this.blogDetails.content;
        this.description = this.blogDetails.description;
        this.authorId = this.blogDetails.authorId;
        this.tags = this.blogDetails.tags;
        this._cd.detectChanges();
      } else {
        this._dialogService.showToast(res.message, false);
        this.close();
      }
    });
  }

  onChange(event: string) {
    this.htmlString = event;
  }

  public onFilesReceived(files: Array<FileInfo>) {
    this.coverImage = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0].file);
    reader.onload = (event: any) => {
      this.coverImage!.dataUrl = event.target.result;
      this._cd.detectChanges();
    };
  }

  async save() {
    this.isReqInProgress.set(true);
    let imageUrls: string[] = [];
    // Upload cover image
    if (!!this.coverImage?.size) {
      imageUrls = await this._apiService.uploadToCloudinary([this.coverImage.file], 'blogs');
      if (imageUrls.length == 0) {
        this._dialogService.showToast('Image uploading failed', false);
        this.isReqInProgress.set(false);
        this._cd.detectChanges();
        return;
      }
    }

    const payload = {
      'id': this.blogDetails?.id,
      'author_id': 1,
      'title': this.title,
      'description': this.description,
      'content': this.htmlString,
      'config': {
        'readTime': this.calculateReadTime(),
      },
      'media': imageUrls.pop() ?? this.blogDetails?.media ?? '',
      'media_type': 'IMAGE',
      'tag_ids': this.tags,
    };
    this._apiService
      .post(this.isEdit ? REST_API.blogEdit : REST_API.blogCreate, payload)
      .then((res: RestApiResponse) => {
        this.isReqInProgress.set(false);
        this._cd.detectChanges();
        res.isSuccess && this._dialogRef.close(true);
      });
  }

  async delete() {
    const result = await this._dialogService.requestConfirmation('Are you sure you want to delete this blog?', {
      tilte: `Delete Blog`,
    });
    if (!result) return;
    this.isDeleteInProgress.set(true);
    this._apiService.post(REST_API.blogDelete, { 'id': this.blogDetails?.id }).then((res: RestApiResponse) => {
      this._dialogService.showToast(res.message, res.isSuccess);
      this.isDeleteInProgress.set(false);
      res.isSuccess && this._dialogRef.close(true);
    });
  }

  get imageUrl() {
    if (this.coverImage?.dataUrl) return this.coverImage.dataUrl;
    if (this.blogDetails?.media) return this.cloudinary.getUrl({ id: this.blogDetails?.media, width: 400 });
    return '';
  }

  get canSave(): boolean {
    return !!(
      !this.isReqInProgress() &&
      this.title.length &&
      this.authorId > 0 &&
      this.htmlString.length &&
      this.imageUrl &&
      this.tags.length &&
      this.description.length
    );
  }

  calculateReadTime(): number {
    const text = this.htmlString?.replace(/<\/?[^>]+(>|$)/g, '') ?? '';
    const words = text?.trim().split(/\s+/);
    const readTime = Math.ceil((words?.length ?? 250) / 250); // Assuming average reading speed of 250 words per minute
    return readTime;
  }

  close() {
    this._dialogRef.close();
  }
}
