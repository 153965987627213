import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { AuthorsListComponent } from '../blogs/authors-list/authors-list.component';
import { BlogListManageComponent } from '../blogs/blog-list-manage/blog-list-manage.component';

@Component({
  selector: 'app-blog-settings',
  standalone: true,
  imports: [MatTabsModule, AuthorsListComponent, BlogListManageComponent],
  templateUrl: './blog-settings.component.html',
  styleUrl: './blog-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogSettingsComponent {}
