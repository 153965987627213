<div class="flex flex-col">
  <quill-editor
    [maxLength]="8000"
    [modules]="modules"
    format="html"
    [(ngModel)]="data"
    (onContentChanged)="onContentChanged($event)"
    [styles]="{ height: height }"
  ></quill-editor>
  <span class="ql-formats">
    <div id="counter"></div>
  </span>
</div>
