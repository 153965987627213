<div
  class="flex flex-col p-8 bg-gray-100 h-full cursor-default max-h-[90vh] min-h-[90vh] xl:min-h-[90vh] overflow-hidden"
>
  <dialog-header [title]="isEdit ? 'Edit Blog' : 'Create Blog'" (onClose)="close()"></dialog-header>

  <!-- Body starts -->
  <div class="flex flex-col p-8 bg-white rounded-lg m-5 flex-grow overflow-y-scroll">
    <ng-container *ngIf="!isEdit || (isEdit && blogDetails); else skeleton">
      <div class="inline-flex gap-12 mb-12">
        <!-- Cover image starts -->
        <div class="flex-1 flex flex-col flex-grow flex-shrink-0 cursor-pointer">
          <app-file-upload
            [maxSize]="3072"
            [fileLimit]="1"
            [allowedTypes]="'.png,.jpg,.jpeg,.webp,.avif'"
            (onFilesUploaded)="onFilesReceived($event)"
            class="h-full"
          >
            <div
              class="flex flex-col cursor-pointer p-5 justify-center items-center flex-grow bg-secondary-light w-full border-dashed border-2 border-secondary-200 rounded-lg"
            >
              <div
                *ngIf="imageUrl"
                class="w-full flex-grow flex flex-col bg-secondary-light rounded-lg shadow bg-center bg-cover bg-no-repeat"
                [style.background-image]="imageUrl! | safe: 'styleUrl'"
              ></div>

              <h3 class="text-gray-500 pt-2 text-12 text-center">
                Drag and drop file here or
                <span class="text-gray-700 font-medium underline">choose file</span>
              </h3>
            </div>
          </app-file-upload>
          <div class="flex flex-row justify-between text-gray-500 pt-2 px-2">
            <h3 class="text-12">File: png, jpg, jpeg</h3>
            <h3 class="text-12">Max size: 3 MB</h3>
          </div>
        </div>
        <!-- Cover image ends -->

        <div class="flex-[3] flex flex-col">
          <!-- Blog Title starts -->
          <ng-container *ngTemplateOutlet="fieldTitle; context: { title: 'Title' }"></ng-container>
          <input [(ngModel)]="title" autocomplete="off" class="input" type="text" />
          <!-- Blog Title ends -->

          <!-- Blog description starts -->
          <ng-container *ngTemplateOutlet="fieldTitle; context: { title: 'Description' }"></ng-container>
          <input [(ngModel)]="description" autocomplete="off" class="input" type="text" />
          <!-- Blog description ends -->

          <!-- Tags -->
          <ng-container *ngTemplateOutlet="fieldTitle; context: { title: 'Tags' }"></ng-container>
          <mat-select [(ngModel)]="tags" placeholder="Tags" multiple class="input">
            @for (tag of data.tags; track $index) {
              <mat-option [value]="tag.id">{{ tag.title }}</mat-option>
            }
          </mat-select>

          <!-- Author list starts-->
          <ng-container *ngTemplateOutlet="fieldTitle; context: { title: 'Author' }"></ng-container>

          <select [(ngModel)]="authorId" class="input">
            <option value="-1" [disabled]="true">Select Author</option>
            <option *ngFor="let item of data.authors" [value]="item.id">
              {{ item.name }}
            </option>
          </select>
          <!-- Author list ends-->
        </div>
      </div>
      <!-- Rich text editor -->
      <rich-text-editor
        [html]="blogDetails?.content"
        [height]="'40vh'"
        (onChange)="onChange($event)"
      ></rich-text-editor>
    </ng-container>
  </div>
  <!-- Body Ends -->

  <div class="inline-flex justify-between">
    <app-button [showLoading]="isDeleteInProgress" (click)="delete()" [ngClass]="{ 'invisible': !isEdit }" class="mr-10"
      >Delete</app-button
    >
    <div class="inline-flex">
      <app-button [disabled]="isReqInProgress() || isDeleteInProgress()" (click)="close()" class="mr-10"
        >Cancel</app-button
      >
      <app-button [disabled]="!canSave || isDeleteInProgress()" [showLoading]="isReqInProgress" (click)="save()">
        Save</app-button
      >
    </div>
  </div>
</div>

<ng-template #fieldTitle let-title="title">
  <div class="inline-flex items-center pt-6">
    <h3 class="capitalize text-14 text-charcoal-dark font-medium pb-2">{{ title }}</h3>
    <span class="text-red-600 font-medium pl-1">*</span>
  </div>
</ng-template>

<!-- skeleton starts -->
<ng-template #skeleton>
  <div class="inline-flex gap-12 mb-12">
    <!-- Cover image starts -->
    <div class="flex-1 flex flex-col flex-grow flex-shrink-0 cursor-pointer">
      <span class="flex-grow w-full rounded-lg shimmer"></span>
      <div class="flex flex-row justify-between text-gray-500 pt-2 px-2">
        <span class="shimmer h-6 rounded-lg w-1/2"></span>
        <span class="shimmer h-6 rounded-lg w-1/3"></span>
      </div>
    </div>
    <!-- Cover image ends -->

    <div class="flex-[3] flex flex-col">
      <span class="shimmer h-6 rounded-lg w-36"></span>
      <span class="shimmer h-16 rounded-lg w-full mt-3"></span>
      <span class="shimmer h-6 rounded-lg w-36 mt-10"></span>
      <span class="shimmer h-16 rounded-lg w-full mt-3"></span>
      <span class="shimmer h-6 rounded-lg w-36 mt-10"></span>
      <span class="shimmer h-16 rounded-lg w-full mt-3"></span>
    </div>
  </div>
</ng-template>
<!-- skeleton ends -->
