<div class="inline-flex items-center justify-between w-full">
  <h3 class="to-charcoal-dark font-bold text-22">{{ title }}</h3>

  <svg
    (click)="onClose.emit()"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="h-12 w-12 text-charcoal cursor-pointer hover:rotate-90 transition-all duration-200 ease-in-out"
  >
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
  </svg>
</div>
