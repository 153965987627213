import { ChangeDetectionStrategy, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { REST_API } from '../../../../../../core/constants/api.constants';
import { ApiService } from '../../../../../../core/services/api.service';
import { Author } from '../../../../../../core/types/blogs';
import { RestApiResponse } from '../../../../../../core/types/core.types';

@Component({
  selector: 'app-authors-list',
  standalone: true,
  imports: [],
  templateUrl: './authors-list.component.html',
  styleUrl: './authors-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorsListComponent implements OnInit {
  authors: WritableSignal<Author[]> = signal([]);

  constructor(
    private dialog: MatDialog,
    private _apiService: ApiService,
  ) {}

  ngOnInit(): void {
    this._fetchBlogs();
  }

  _fetchBlogs() {
    this._apiService.get(REST_API.authorList).then((res: RestApiResponse) => {
      if (res.isSuccess) {
        // const blogs = [];
        // for (const json of res.data) {
        //   // blogs.push(BlogItem.fromJson(json));
        // }
        // this.blogs.set(blogs);
      }
    });
  }

  add() {
    // let authors = [
    //   {
    //     'id': 1,
    //     'created_by': '',
    //     'updated_by': '',
    //     'created_utc_millis': 1722233509900,
    //     'updated_utc_millis': 1722233509900,
    //     'name': 'Karthik 2',
    //     'about': 'Developer / Blogger',
    //     'image': '',
    //     'config': {},
    //   },
    // ];
    // this.dialog.open(AdminBlogManageComponent, {
    //   data: { authors: authors },
    //   autoFocus: false,
    //   minWidth: '50vw',
    //   maxWidth: '95vw',
    //   minHeight: '50vh',
    //   maxHeight: '95vh',
    // });
  }
}
