import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContentChange, QuillModule, QuillModules } from 'ngx-quill';
import Quill from 'quill';

const font: any = Quill.import('formats/font');
const fonts = ['Manrope', 'Marcellus', 'serif', 'monospace'];
font.whitelist = fonts;
Quill.register(font, true);

@Component({
  selector: 'rich-text-editor',
  standalone: true,
  imports: [CommonModule, FormsModule, QuillModule],
  templateUrl: './rich-text-editor.component.html',
  styleUrl: './rich-text-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextEditorComponent {
  @Input() height = '40vh';
  @Input() html? = '';
  @Output() onChange = new EventEmitter<string>();

  data = '';
  modules: QuillModules = {
    // counter: { container: '#counter', unit: 'letter' },
    table: true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'size': ['small', false, 'large', 'huge'] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'font': fonts }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
      ['link', 'image'],
    ],
  };

  ngOnInit(): void {
    this.data = this.html ?? '';
  }

  onContentChanged(event: ContentChange) {
    this.onChange.emit(event.html ?? '');
  }
}
