import { ChangeDetectionStrategy, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom, forkJoin } from 'rxjs';
import { REST_API } from '../../../../../../core/constants/api.constants';
import { ApiService } from '../../../../../../core/services/api.service';
import { CloudinaryService } from '../../../../../../core/services/cloudinary.service';
import { DialogService } from '../../../../../../core/services/dialog.service';
import { Author, BlogItem, BlogTag } from '../../../../../../core/types/blogs';
import { RestApiResponse } from '../../../../../../core/types/core.types';
import { ButtonComponent } from '../../../../../../shared/components/button/button.component';
import { BlogItemComponent } from '../../../../../blogs/blog-item/blog-item.component';
import { AdminBlogManageComponent } from '../../../../blogs/admin-blog-manage/admin-blog-manage.component';

@Component({
  selector: 'blog-list-manage',
  standalone: true,
  imports: [BlogItemComponent, ButtonComponent],
  templateUrl: './blog-list-manage.component.html',
  styleUrl: './blog-list-manage.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogListManageComponent implements OnInit {
  blogs: WritableSignal<BlogItem[]> = signal([]);
  authors: Author[] = [];
  tags: BlogTag[] = [];
  tagMap: Record<number, string> = {};
  isloading = signal(true);
  constructor(
    private dialog: MatDialog,
    private _apiService: ApiService,
    public cloudinary: CloudinaryService,
    private _dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this._fetchBlogs();
  }

  private _fetchBlogs() {
    forkJoin([
      this._apiService.get(REST_API.authorList),
      this._apiService.get(REST_API.blogList),
      this._apiService.post(REST_API.blogTagsList),
    ]).subscribe(([authorResponse, blogResponse, tagResponse]: [RestApiResponse, RestApiResponse, RestApiResponse]) => {
      if (authorResponse.isSuccess) {
        this.authors = authorResponse.data;
      }
      if (tagResponse.isSuccess) {
        this.tags = tagResponse.data;
        for (const tag of this.tags) {
          this.tagMap[tag.id] = tag.title;
        }
      }
      if (blogResponse.isSuccess) {
        const blogs: BlogItem[] = [];
        for (const json of blogResponse.data) {
          const blog = BlogItem.fromJson(json);
          blogs.push(blog);
        }
        this.blogs.set(blogs);
      }
      this.isloading.set(false);
    });
  }

  edit(blog?: BlogItem) {
    const dialogRef = this.dialog.open(AdminBlogManageComponent, {
      data: { authors: this.authors, blogId: blog?.id, tags: this.tags },
      autoFocus: false,
      minWidth: '50vw',
      maxWidth: '95vw',
      minHeight: '50vh',
      maxHeight: '95vh',
    });

    firstValueFrom(dialogRef.afterClosed()).then((result: boolean) => {
      if (result) {
        this.isloading.set(true);
        this._fetchBlogs();
      }
    });
  }

  async delete(blog: BlogItem) {
    const result = await this._dialogService.requestConfirmation('Are you sure you want to delete this blog?', {
      tilte: `Delete Blog`,
    });
    if (!result) return;

    this._apiService.post(REST_API.blogDelete, { 'id': blog.id }).then((res: RestApiResponse) => {
      // this._dialogService.showToast(res.message, res.isSuccess);
      if (res.isSuccess) {
        this.isloading.set(true);
        this._fetchBlogs();
      }
    });
  }
}
