<div class="flex flex-col">
  <mat-tab-group color="accent" animationDuration="0ms" preserveContent>
    <mat-tab label="Blogs">
      <ng-template matTabContent>
        <blog-list-manage></blog-list-manage>
      </ng-template>
    </mat-tab>
    <mat-tab label="Authors">
      <ng-template matTabContent>
        <div>
          <app-authors-list></app-authors-list>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Tags">
      <ng-template matTabContent>
        <h3>Coming Later...</h3>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
